import MenuService from '@/services/MenuService';

export const state = () => ({
  data: {},
});

export const mutations = {
  setMenuData(state, { data }) {
    state.data = data;
  },
};

export const actions = {
  fetchMenu({ commit }) {
    const menuService = new MenuService({
      http: this.$http,
      auth: this.$auth,
    });

    return menuService
      .fetchMenu({
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      })
      .then((response) => {
        commit('setMenuData', { data: response.data });
        return response.data;
      })
      .catch((err) => {
        throw err;
      });

    console.log('her');
  },
};
