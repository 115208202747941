import { AppService } from './AppService';

export default class MenuService extends AppService {
  constructor({ http, auth, store }) {
    super({ http, auth, store });
  }

  fetchMenu() {
    return this.http.API.get(`menu/menu.php?IdMenu=30&LanguageIsoCode=fr`)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  }
}
