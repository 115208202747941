import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _875f0476 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _cfd30080 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _235ce452 = () => interopDefault(import('../pages/refer-a-friend/index.vue' /* webpackChunkName: "pages/refer-a-friend/index" */))
const _57622e33 = () => interopDefault(import('../pages/shops/index.vue' /* webpackChunkName: "pages/shops/index" */))
const _6198aa61 = () => interopDefault(import('../pages/lookbooks/index.vue' /* webpackChunkName: "pages/lookbooks/index" */))
const _61a79c43 = () => interopDefault(import('../pages/marque/index.vue' /* webpackChunkName: "pages/marque/index" */))
const _73fa3433 = () => interopDefault(import('../pages/loyalty-program/index.vue' /* webpackChunkName: "pages/loyalty-program/index" */))
const _57daab6f = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _2ca52408 = () => interopDefault(import('../pages/account/address/index.vue' /* webpackChunkName: "pages/account/address/index" */))
const _d86479aa = () => interopDefault(import('../pages/account/autologin.vue' /* webpackChunkName: "pages/account/autologin" */))
const _7fbcffe8 = () => interopDefault(import('../pages/account/favorites.vue' /* webpackChunkName: "pages/account/favorites" */))
const _d2e2c910 = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _0c850aa7 = () => interopDefault(import('../pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _1ae900c2 = () => interopDefault(import('../pages/account/referral/index.vue' /* webpackChunkName: "pages/account/referral/index" */))
const _ba044ad2 = () => interopDefault(import('../pages/account/returns/index.vue' /* webpackChunkName: "pages/account/returns/index" */))
const _460f9189 = () => interopDefault(import('../pages/account/service/index.vue' /* webpackChunkName: "pages/account/service/index" */))
const _e323ca40 = () => interopDefault(import('../pages/cart/connection.vue' /* webpackChunkName: "pages/cart/connection" */))
const _8bcd6114 = () => interopDefault(import('../pages/cart/delivery.vue' /* webpackChunkName: "pages/cart/delivery" */))
const _7f85d174 = () => interopDefault(import('../pages/cart/payment.vue' /* webpackChunkName: "pages/cart/payment" */))
const _a502a4a2 = () => interopDefault(import('../pages/content/cgv.vue' /* webpackChunkName: "pages/content/cgv" */))
const _c1c0ab14 = () => interopDefault(import('../pages/content/faq/index.vue' /* webpackChunkName: "pages/content/faq/index" */))
const _2f0f09c2 = () => interopDefault(import('../pages/content/legal-notice.vue' /* webpackChunkName: "pages/content/legal-notice" */))
const _038ca98a = () => interopDefault(import('../pages/content/privacy-policy.vue' /* webpackChunkName: "pages/content/privacy-policy" */))
const _560682c6 = () => interopDefault(import('../pages/account/informations.vue' /* webpackChunkName: "pages/account/informations" */))
const _2ffbf109 = () => interopDefault(import('../pages/password/new.vue' /* webpackChunkName: "pages/password/new" */))
const _0849f610 = () => interopDefault(import('../pages/password/reset.vue' /* webpackChunkName: "pages/password/reset" */))
const _2a33467d = () => interopDefault(import('../pages/password/validate.vue' /* webpackChunkName: "pages/password/validate" */))
const _e51b4554 = () => interopDefault(import('../pages/account/address/new.vue' /* webpackChunkName: "pages/account/address/new" */))
const _1317783c = () => interopDefault(import('../pages/account/components/GetCardCadeau/index.vue' /* webpackChunkName: "pages/account/components/GetCardCadeau/index" */))
const _103c8961 = () => interopDefault(import('../pages/account/service/tickets/index.vue' /* webpackChunkName: "pages/account/service/tickets/index" */))
const _9243a2b4 = () => interopDefault(import('../pages/cart/components/CartItemCodesPromo/index.vue' /* webpackChunkName: "pages/cart/components/CartItemCodesPromo/index" */))
const _361a5c86 = () => interopDefault(import('../pages/account/components/GetCardCadeau/GetCardCadeauShow.vue' /* webpackChunkName: "pages/account/components/GetCardCadeau/GetCardCadeauShow" */))
const _4ed8a3ac = () => interopDefault(import('../pages/account/components/GetCardCadeau/GetCardCadeauStep1.vue' /* webpackChunkName: "pages/account/components/GetCardCadeau/GetCardCadeauStep1" */))
const _4ee6bb2d = () => interopDefault(import('../pages/account/components/GetCardCadeau/GetCardCadeauStep2.vue' /* webpackChunkName: "pages/account/components/GetCardCadeau/GetCardCadeauStep2" */))
const _785ab589 = () => interopDefault(import('../pages/cart/components/CartItemCodesPromo/CodePromoItem.vue' /* webpackChunkName: "pages/cart/components/CartItemCodesPromo/CodePromoItem" */))
const _63064338 = () => interopDefault(import('../pages/cart/components/payments/alma/AlmaForm.vue' /* webpackChunkName: "pages/cart/components/payments/alma/AlmaForm" */))
const _9cf00936 = () => interopDefault(import('../pages/cart/components/payments/alma/AlmaFormHeader.vue' /* webpackChunkName: "pages/cart/components/payments/alma/AlmaFormHeader" */))
const _cd888d7a = () => interopDefault(import('../pages/cart/components/payments/hipay/BancCardHipay/index.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/BancCardHipay/index" */))
const _b4722b72 = () => interopDefault(import('../pages/cart/components/payments/hipay/Bancontact/index.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Bancontact/index" */))
const _0476f429 = () => interopDefault(import('../pages/cart/components/payments/hipay/Giropay/index.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Giropay/index" */))
const _19574515 = () => interopDefault(import('../pages/cart/components/payments/hipay/Ideal/index.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Ideal/index" */))
const _79f9883d = () => interopDefault(import('../pages/cart/components/payments/hipay/Sofort/index.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Sofort/index" */))
const _03b4f410 = () => interopDefault(import('../pages/cart/components/payments/klarna/KlarnaForm.vue' /* webpackChunkName: "pages/cart/components/payments/klarna/KlarnaForm" */))
const _415a2e25 = () => interopDefault(import('../pages/cart/components/payments/klarna/KlarnaFormHeader.vue' /* webpackChunkName: "pages/cart/components/payments/klarna/KlarnaFormHeader" */))
const _984d93d0 = () => interopDefault(import('../pages/cart/components/payments/paypal/PayPalForm.vue' /* webpackChunkName: "pages/cart/components/payments/paypal/PayPalForm" */))
const _30209bf2 = () => interopDefault(import('../pages/cart/components/payments/paypal/PayPalForm-old.vue' /* webpackChunkName: "pages/cart/components/payments/paypal/PayPalForm-old" */))
const _c0e5f336 = () => interopDefault(import('../pages/cart/components/payments/paypal/PaypalFormHeader.vue' /* webpackChunkName: "pages/cart/components/payments/paypal/PaypalFormHeader" */))
const _eb991610 = () => interopDefault(import('../pages/cart/components/payments/stripe/StripeForm.vue' /* webpackChunkName: "pages/cart/components/payments/stripe/StripeForm" */))
const _7e0e45b6 = () => interopDefault(import('../pages/cart/components/payments/stripe/StripeFormHeader.vue' /* webpackChunkName: "pages/cart/components/payments/stripe/StripeFormHeader" */))
const _54778501 = () => interopDefault(import('../pages/cart/components/payments/hipay/BancCardHipay/BancCardHipayHeader.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/BancCardHipay/BancCardHipayHeader" */))
const _df3537e2 = () => interopDefault(import('../pages/cart/components/payments/hipay/BancCardHipay/BankCardForm.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/BancCardHipay/BankCardForm" */))
const _d4c74320 = () => interopDefault(import('../pages/cart/components/payments/hipay/Bancontact/BanContactForm.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Bancontact/BanContactForm" */))
const _6e96f2d9 = () => interopDefault(import('../pages/cart/components/payments/hipay/Bancontact/BancontactHeader.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Bancontact/BancontactHeader" */))
const _76fe96f8 = () => interopDefault(import('../pages/cart/components/payments/hipay/Giropay/GiropayForm.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Giropay/GiropayForm" */))
const _608253cd = () => interopDefault(import('../pages/cart/components/payments/hipay/Giropay/GiropayHeader.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Giropay/GiropayHeader" */))
const _5cc6a1dc = () => interopDefault(import('../pages/cart/components/payments/hipay/Ideal/IdealForm.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Ideal/IdealForm" */))
const _01fb69b6 = () => interopDefault(import('../pages/cart/components/payments/hipay/Ideal/IdealHeader.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Ideal/IdealHeader" */))
const _1f82ac60 = () => interopDefault(import('../pages/cart/components/payments/hipay/Sofort/SofortForm.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Sofort/SofortForm" */))
const _d6cfb3ce = () => interopDefault(import('../pages/cart/components/payments/hipay/Sofort/SofortHeader.vue' /* webpackChunkName: "pages/cart/components/payments/hipay/Sofort/SofortHeader" */))
const _3468e383 = () => interopDefault(import('../pages/account/service/request/_productAttribute.vue' /* webpackChunkName: "pages/account/service/request/_productAttribute" */))
const _fdc21164 = () => interopDefault(import('../pages/account/service/tickets/_idTickets.vue' /* webpackChunkName: "pages/account/service/tickets/_idTickets" */))
const _644f4394 = () => interopDefault(import('../pages/account/address/_idAddress.vue' /* webpackChunkName: "pages/account/address/_idAddress" */))
const _63538acf = () => interopDefault(import('../pages/account/orders/_id.vue' /* webpackChunkName: "pages/account/orders/_id" */))
const _26abf51d = () => interopDefault(import('../pages/account/return/_id/index.vue' /* webpackChunkName: "pages/account/return/_id/index" */))
const _5ba9d202 = () => interopDefault(import('../pages/account/returns/_id/index.vue' /* webpackChunkName: "pages/account/returns/_id/index" */))
const _155b3178 = () => interopDefault(import('../pages/content/faq/_permalink.vue' /* webpackChunkName: "pages/content/faq/_permalink" */))
const _7d8a6faa = () => interopDefault(import('../pages/account/return/_id/confirmed.vue' /* webpackChunkName: "pages/account/return/_id/confirmed" */))
const _dd661226 = () => interopDefault(import('../pages/cart/thanks/_state/_idorder.vue' /* webpackChunkName: "pages/cart/thanks/_state/_idorder" */))
const _2604ab18 = () => interopDefault(import('../pages/account/_tab/index.vue' /* webpackChunkName: "pages/account/_tab/index" */))
const _71931312 = () => interopDefault(import('../pages/authenticate/_route.vue' /* webpackChunkName: "pages/authenticate/_route" */))
const _0b4e5351 = () => interopDefault(import('../pages/blog/_uid.vue' /* webpackChunkName: "pages/blog/_uid" */))
const _02a7f887 = () => interopDefault(import('../pages/blogs/_permalink.vue' /* webpackChunkName: "pages/blogs/_permalink" */))
const _01f35850 = () => interopDefault(import('../pages/shop/_permalink.vue' /* webpackChunkName: "pages/shop/_permalink" */))
const _5aa72726 = () => interopDefault(import('../pages/lookbooks/_permalink.vue' /* webpackChunkName: "pages/lookbooks/_permalink" */))
const _3bd97d2d = () => interopDefault(import('../pages/product/_product.vue' /* webpackChunkName: "pages/product/_product" */))
const _48d42e77 = () => interopDefault(import('../pages/referral/_code.vue' /* webpackChunkName: "pages/referral/_code" */))
const _2432a86c = () => interopDefault(import('../pages/shop-the-look/_product.vue' /* webpackChunkName: "pages/shop-the-look/_product" */))
const _22f3b962 = () => interopDefault(import('../pages/account/_tab/_tab2.vue' /* webpackChunkName: "pages/account/_tab/_tab2" */))
const _6ad96af7 = () => interopDefault(import('../pages/_cat/index.vue' /* webpackChunkName: "pages/_cat/index" */))
const _c73cc2ca = () => interopDefault(import('../pages/_cat/_product.vue' /* webpackChunkName: "pages/_cat/_product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _875f0476,
    name: "index___de"
  }, {
    path: "/en",
    component: _875f0476,
    name: "index___en"
  }, {
    path: "/fr",
    component: _875f0476,
    name: "index___fr"
  }, {
    path: "/de/cart",
    component: _cfd30080,
    name: "cart___de"
  }, {
    path: "/de/empfehlen-sie-einen-freund",
    component: _235ce452,
    name: "refer-a-friend___de"
  }, {
    path: "/de/geschafte",
    component: _57622e33,
    name: "shops___de"
  }, {
    path: "/de/lookbooks",
    component: _6198aa61,
    name: "lookbooks___de"
  }, {
    path: "/de/marque",
    component: _61a79c43,
    name: "marque___de"
  }, {
    path: "/de/programm-fidelite",
    component: _73fa3433,
    name: "loyalty-program___de"
  }, {
    path: "/de/test",
    component: _57daab6f,
    name: "test___de"
  }, {
    path: "/en/cart",
    component: _cfd30080,
    name: "cart___en"
  }, {
    path: "/en/lookbooks",
    component: _6198aa61,
    name: "lookbooks___en"
  }, {
    path: "/en/loyalty-program",
    component: _73fa3433,
    name: "loyalty-program___en"
  }, {
    path: "/en/marque",
    component: _61a79c43,
    name: "marque___en"
  }, {
    path: "/en/refer-a-friend",
    component: _235ce452,
    name: "refer-a-friend___en"
  }, {
    path: "/en/shops",
    component: _57622e33,
    name: "shops___en"
  }, {
    path: "/en/test",
    component: _57daab6f,
    name: "test___en"
  }, {
    path: "/fr/boutiques",
    component: _57622e33,
    name: "shops___fr"
  }, {
    path: "/fr/cart",
    component: _cfd30080,
    name: "cart___fr"
  }, {
    path: "/fr/lookbooks",
    component: _6198aa61,
    name: "lookbooks___fr"
  }, {
    path: "/fr/marque",
    component: _61a79c43,
    name: "marque___fr"
  }, {
    path: "/fr/parrainer-un-ami",
    component: _235ce452,
    name: "refer-a-friend___fr"
  }, {
    path: "/fr/programme-fidelite",
    component: _73fa3433,
    name: "loyalty-program___fr"
  }, {
    path: "/fr/test",
    component: _57daab6f,
    name: "test___fr"
  }, {
    path: "/de/account/address",
    component: _2ca52408,
    name: "account-address___de"
  }, {
    path: "/de/account/autologin",
    component: _d86479aa,
    name: "account-autologin___de"
  }, {
    path: "/de/account/favorites",
    component: _7fbcffe8,
    name: "account-favorites___de"
  }, {
    path: "/de/account/newsletter",
    component: _d2e2c910,
    name: "account-newsletter___de"
  }, {
    path: "/de/account/orders",
    component: _0c850aa7,
    name: "account-orders___de"
  }, {
    path: "/de/account/referral",
    component: _1ae900c2,
    name: "account-referral___de"
  }, {
    path: "/de/account/returns",
    component: _ba044ad2,
    name: "account-returns___de"
  }, {
    path: "/de/account/service",
    component: _460f9189,
    name: "account-service___de"
  }, {
    path: "/de/cart/connection",
    component: _e323ca40,
    name: "cart-connection___de"
  }, {
    path: "/de/cart/delivery",
    component: _8bcd6114,
    name: "cart-delivery___de"
  }, {
    path: "/de/cart/payment",
    component: _7f85d174,
    name: "cart-payment___de"
  }, {
    path: "/de/content/cgv",
    component: _a502a4a2,
    name: "content-cgv___de"
  }, {
    path: "/de/content/faq",
    component: _c1c0ab14,
    name: "content-faq___de"
  }, {
    path: "/de/content/legal-notice",
    component: _2f0f09c2,
    name: "content-legal-notice___de"
  }, {
    path: "/de/content/privacy-policy",
    component: _038ca98a,
    name: "content-privacy-policy___de"
  }, {
    path: "/de/konto/information",
    component: _560682c6,
    name: "account-informations___de"
  }, {
    path: "/de/password/new",
    component: _2ffbf109,
    name: "password-new___de"
  }, {
    path: "/de/password/reset",
    component: _0849f610,
    name: "password-reset___de"
  }, {
    path: "/de/password/validate",
    component: _2a33467d,
    name: "password-validate___de"
  }, {
    path: "/en/account/address",
    component: _2ca52408,
    name: "account-address___en"
  }, {
    path: "/en/account/autologin",
    component: _d86479aa,
    name: "account-autologin___en"
  }, {
    path: "/en/account/favorites",
    component: _7fbcffe8,
    name: "account-favorites___en"
  }, {
    path: "/en/account/informations",
    component: _560682c6,
    name: "account-informations___en"
  }, {
    path: "/en/account/newsletter",
    component: _d2e2c910,
    name: "account-newsletter___en"
  }, {
    path: "/en/account/orders",
    component: _0c850aa7,
    name: "account-orders___en"
  }, {
    path: "/en/account/referral",
    component: _1ae900c2,
    name: "account-referral___en"
  }, {
    path: "/en/account/returns",
    component: _ba044ad2,
    name: "account-returns___en"
  }, {
    path: "/en/account/service",
    component: _460f9189,
    name: "account-service___en"
  }, {
    path: "/en/cart/connection",
    component: _e323ca40,
    name: "cart-connection___en"
  }, {
    path: "/en/cart/delivery",
    component: _8bcd6114,
    name: "cart-delivery___en"
  }, {
    path: "/en/cart/payment",
    component: _7f85d174,
    name: "cart-payment___en"
  }, {
    path: "/en/content/cgv",
    component: _a502a4a2,
    name: "content-cgv___en"
  }, {
    path: "/en/content/faq",
    component: _c1c0ab14,
    name: "content-faq___en"
  }, {
    path: "/en/content/legal-notice",
    component: _2f0f09c2,
    name: "content-legal-notice___en"
  }, {
    path: "/en/content/privacy-policy",
    component: _038ca98a,
    name: "content-privacy-policy___en"
  }, {
    path: "/en/password/new",
    component: _2ffbf109,
    name: "password-new___en"
  }, {
    path: "/en/password/reset",
    component: _0849f610,
    name: "password-reset___en"
  }, {
    path: "/en/password/validate",
    component: _2a33467d,
    name: "password-validate___en"
  }, {
    path: "/fr/account/address",
    component: _2ca52408,
    name: "account-address___fr"
  }, {
    path: "/fr/account/autologin",
    component: _d86479aa,
    name: "account-autologin___fr"
  }, {
    path: "/fr/account/favorites",
    component: _7fbcffe8,
    name: "account-favorites___fr"
  }, {
    path: "/fr/account/informations",
    component: _560682c6,
    name: "account-informations___fr"
  }, {
    path: "/fr/account/newsletter",
    component: _d2e2c910,
    name: "account-newsletter___fr"
  }, {
    path: "/fr/account/orders",
    component: _0c850aa7,
    name: "account-orders___fr"
  }, {
    path: "/fr/account/referral",
    component: _1ae900c2,
    name: "account-referral___fr"
  }, {
    path: "/fr/account/returns",
    component: _ba044ad2,
    name: "account-returns___fr"
  }, {
    path: "/fr/account/service",
    component: _460f9189,
    name: "account-service___fr"
  }, {
    path: "/fr/cart/connection",
    component: _e323ca40,
    name: "cart-connection___fr"
  }, {
    path: "/fr/cart/delivery",
    component: _8bcd6114,
    name: "cart-delivery___fr"
  }, {
    path: "/fr/cart/payment",
    component: _7f85d174,
    name: "cart-payment___fr"
  }, {
    path: "/fr/content/cgv",
    component: _a502a4a2,
    name: "content-cgv___fr"
  }, {
    path: "/fr/content/faq",
    component: _c1c0ab14,
    name: "content-faq___fr"
  }, {
    path: "/fr/content/legal-notice",
    component: _2f0f09c2,
    name: "content-legal-notice___fr"
  }, {
    path: "/fr/content/privacy-policy",
    component: _038ca98a,
    name: "content-privacy-policy___fr"
  }, {
    path: "/fr/password/new",
    component: _2ffbf109,
    name: "password-new___fr"
  }, {
    path: "/fr/password/reset",
    component: _0849f610,
    name: "password-reset___fr"
  }, {
    path: "/fr/password/validate",
    component: _2a33467d,
    name: "password-validate___fr"
  }, {
    path: "/de/account/address/new",
    component: _e51b4554,
    name: "account-address-new___de"
  }, {
    path: "/de/account/components/GetCardCadeau",
    component: _1317783c,
    name: "account-components-GetCardCadeau___de"
  }, {
    path: "/de/account/service/tickets",
    component: _103c8961,
    name: "account-service-tickets___de"
  }, {
    path: "/de/cart/components/CartItemCodesPromo",
    component: _9243a2b4,
    name: "cart-components-CartItemCodesPromo___de"
  }, {
    path: "/en/account/address/new",
    component: _e51b4554,
    name: "account-address-new___en"
  }, {
    path: "/en/account/components/GetCardCadeau",
    component: _1317783c,
    name: "account-components-GetCardCadeau___en"
  }, {
    path: "/en/account/service/tickets",
    component: _103c8961,
    name: "account-service-tickets___en"
  }, {
    path: "/en/cart/components/CartItemCodesPromo",
    component: _9243a2b4,
    name: "cart-components-CartItemCodesPromo___en"
  }, {
    path: "/fr/account/address/new",
    component: _e51b4554,
    name: "account-address-new___fr"
  }, {
    path: "/fr/account/components/GetCardCadeau",
    component: _1317783c,
    name: "account-components-GetCardCadeau___fr"
  }, {
    path: "/fr/account/service/tickets",
    component: _103c8961,
    name: "account-service-tickets___fr"
  }, {
    path: "/fr/cart/components/CartItemCodesPromo",
    component: _9243a2b4,
    name: "cart-components-CartItemCodesPromo___fr"
  }, {
    path: "/de/account/components/GetCardCadeau/GetCardCadeauShow",
    component: _361a5c86,
    name: "account-components-GetCardCadeau-GetCardCadeauShow___de"
  }, {
    path: "/de/account/components/GetCardCadeau/GetCardCadeauStep1",
    component: _4ed8a3ac,
    name: "account-components-GetCardCadeau-GetCardCadeauStep1___de"
  }, {
    path: "/de/account/components/GetCardCadeau/GetCardCadeauStep2",
    component: _4ee6bb2d,
    name: "account-components-GetCardCadeau-GetCardCadeauStep2___de"
  }, {
    path: "/de/cart/components/CartItemCodesPromo/CodePromoItem",
    component: _785ab589,
    name: "cart-components-CartItemCodesPromo-CodePromoItem___de"
  }, {
    path: "/en/account/components/GetCardCadeau/GetCardCadeauShow",
    component: _361a5c86,
    name: "account-components-GetCardCadeau-GetCardCadeauShow___en"
  }, {
    path: "/en/account/components/GetCardCadeau/GetCardCadeauStep1",
    component: _4ed8a3ac,
    name: "account-components-GetCardCadeau-GetCardCadeauStep1___en"
  }, {
    path: "/en/account/components/GetCardCadeau/GetCardCadeauStep2",
    component: _4ee6bb2d,
    name: "account-components-GetCardCadeau-GetCardCadeauStep2___en"
  }, {
    path: "/en/cart/components/CartItemCodesPromo/CodePromoItem",
    component: _785ab589,
    name: "cart-components-CartItemCodesPromo-CodePromoItem___en"
  }, {
    path: "/fr/account/components/GetCardCadeau/GetCardCadeauShow",
    component: _361a5c86,
    name: "account-components-GetCardCadeau-GetCardCadeauShow___fr"
  }, {
    path: "/fr/account/components/GetCardCadeau/GetCardCadeauStep1",
    component: _4ed8a3ac,
    name: "account-components-GetCardCadeau-GetCardCadeauStep1___fr"
  }, {
    path: "/fr/account/components/GetCardCadeau/GetCardCadeauStep2",
    component: _4ee6bb2d,
    name: "account-components-GetCardCadeau-GetCardCadeauStep2___fr"
  }, {
    path: "/fr/cart/components/CartItemCodesPromo/CodePromoItem",
    component: _785ab589,
    name: "cart-components-CartItemCodesPromo-CodePromoItem___fr"
  }, {
    path: "/de/cart/components/payments/alma/AlmaForm",
    component: _63064338,
    name: "cart-components-payments-alma-AlmaForm___de"
  }, {
    path: "/de/cart/components/payments/alma/AlmaFormHeader",
    component: _9cf00936,
    name: "cart-components-payments-alma-AlmaFormHeader___de"
  }, {
    path: "/de/cart/components/payments/hipay/BancCardHipay",
    component: _cd888d7a,
    name: "cart-components-payments-hipay-BancCardHipay___de"
  }, {
    path: "/de/cart/components/payments/hipay/Bancontact",
    component: _b4722b72,
    name: "cart-components-payments-hipay-Bancontact___de"
  }, {
    path: "/de/cart/components/payments/hipay/Giropay",
    component: _0476f429,
    name: "cart-components-payments-hipay-Giropay___de"
  }, {
    path: "/de/cart/components/payments/hipay/Ideal",
    component: _19574515,
    name: "cart-components-payments-hipay-Ideal___de"
  }, {
    path: "/de/cart/components/payments/hipay/Sofort",
    component: _79f9883d,
    name: "cart-components-payments-hipay-Sofort___de"
  }, {
    path: "/de/cart/components/payments/klarna/KlarnaForm",
    component: _03b4f410,
    name: "cart-components-payments-klarna-KlarnaForm___de"
  }, {
    path: "/de/cart/components/payments/klarna/KlarnaFormHeader",
    component: _415a2e25,
    name: "cart-components-payments-klarna-KlarnaFormHeader___de"
  }, {
    path: "/de/cart/components/payments/paypal/PayPalForm",
    component: _984d93d0,
    name: "cart-components-payments-paypal-PayPalForm___de"
  }, {
    path: "/de/cart/components/payments/paypal/PayPalForm-old",
    component: _30209bf2,
    name: "cart-components-payments-paypal-PayPalForm-old___de"
  }, {
    path: "/de/cart/components/payments/paypal/PaypalFormHeader",
    component: _c0e5f336,
    name: "cart-components-payments-paypal-PaypalFormHeader___de"
  }, {
    path: "/de/cart/components/payments/stripe/StripeForm",
    component: _eb991610,
    name: "cart-components-payments-stripe-StripeForm___de"
  }, {
    path: "/de/cart/components/payments/stripe/StripeFormHeader",
    component: _7e0e45b6,
    name: "cart-components-payments-stripe-StripeFormHeader___de"
  }, {
    path: "/en/cart/components/payments/alma/AlmaForm",
    component: _63064338,
    name: "cart-components-payments-alma-AlmaForm___en"
  }, {
    path: "/en/cart/components/payments/alma/AlmaFormHeader",
    component: _9cf00936,
    name: "cart-components-payments-alma-AlmaFormHeader___en"
  }, {
    path: "/en/cart/components/payments/hipay/BancCardHipay",
    component: _cd888d7a,
    name: "cart-components-payments-hipay-BancCardHipay___en"
  }, {
    path: "/en/cart/components/payments/hipay/Bancontact",
    component: _b4722b72,
    name: "cart-components-payments-hipay-Bancontact___en"
  }, {
    path: "/en/cart/components/payments/hipay/Giropay",
    component: _0476f429,
    name: "cart-components-payments-hipay-Giropay___en"
  }, {
    path: "/en/cart/components/payments/hipay/Ideal",
    component: _19574515,
    name: "cart-components-payments-hipay-Ideal___en"
  }, {
    path: "/en/cart/components/payments/hipay/Sofort",
    component: _79f9883d,
    name: "cart-components-payments-hipay-Sofort___en"
  }, {
    path: "/en/cart/components/payments/klarna/KlarnaForm",
    component: _03b4f410,
    name: "cart-components-payments-klarna-KlarnaForm___en"
  }, {
    path: "/en/cart/components/payments/klarna/KlarnaFormHeader",
    component: _415a2e25,
    name: "cart-components-payments-klarna-KlarnaFormHeader___en"
  }, {
    path: "/en/cart/components/payments/paypal/PayPalForm",
    component: _984d93d0,
    name: "cart-components-payments-paypal-PayPalForm___en"
  }, {
    path: "/en/cart/components/payments/paypal/PayPalForm-old",
    component: _30209bf2,
    name: "cart-components-payments-paypal-PayPalForm-old___en"
  }, {
    path: "/en/cart/components/payments/paypal/PaypalFormHeader",
    component: _c0e5f336,
    name: "cart-components-payments-paypal-PaypalFormHeader___en"
  }, {
    path: "/en/cart/components/payments/stripe/StripeForm",
    component: _eb991610,
    name: "cart-components-payments-stripe-StripeForm___en"
  }, {
    path: "/en/cart/components/payments/stripe/StripeFormHeader",
    component: _7e0e45b6,
    name: "cart-components-payments-stripe-StripeFormHeader___en"
  }, {
    path: "/fr/cart/components/payments/alma/AlmaForm",
    component: _63064338,
    name: "cart-components-payments-alma-AlmaForm___fr"
  }, {
    path: "/fr/cart/components/payments/alma/AlmaFormHeader",
    component: _9cf00936,
    name: "cart-components-payments-alma-AlmaFormHeader___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/BancCardHipay",
    component: _cd888d7a,
    name: "cart-components-payments-hipay-BancCardHipay___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Bancontact",
    component: _b4722b72,
    name: "cart-components-payments-hipay-Bancontact___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Giropay",
    component: _0476f429,
    name: "cart-components-payments-hipay-Giropay___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Ideal",
    component: _19574515,
    name: "cart-components-payments-hipay-Ideal___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Sofort",
    component: _79f9883d,
    name: "cart-components-payments-hipay-Sofort___fr"
  }, {
    path: "/fr/cart/components/payments/klarna/KlarnaForm",
    component: _03b4f410,
    name: "cart-components-payments-klarna-KlarnaForm___fr"
  }, {
    path: "/fr/cart/components/payments/klarna/KlarnaFormHeader",
    component: _415a2e25,
    name: "cart-components-payments-klarna-KlarnaFormHeader___fr"
  }, {
    path: "/fr/cart/components/payments/paypal/PayPalForm",
    component: _984d93d0,
    name: "cart-components-payments-paypal-PayPalForm___fr"
  }, {
    path: "/fr/cart/components/payments/paypal/PayPalForm-old",
    component: _30209bf2,
    name: "cart-components-payments-paypal-PayPalForm-old___fr"
  }, {
    path: "/fr/cart/components/payments/paypal/PaypalFormHeader",
    component: _c0e5f336,
    name: "cart-components-payments-paypal-PaypalFormHeader___fr"
  }, {
    path: "/fr/cart/components/payments/stripe/StripeForm",
    component: _eb991610,
    name: "cart-components-payments-stripe-StripeForm___fr"
  }, {
    path: "/fr/cart/components/payments/stripe/StripeFormHeader",
    component: _7e0e45b6,
    name: "cart-components-payments-stripe-StripeFormHeader___fr"
  }, {
    path: "/de/cart/components/payments/hipay/BancCardHipay/BancCardHipayHeader",
    component: _54778501,
    name: "cart-components-payments-hipay-BancCardHipay-BancCardHipayHeader___de"
  }, {
    path: "/de/cart/components/payments/hipay/BancCardHipay/BankCardForm",
    component: _df3537e2,
    name: "cart-components-payments-hipay-BancCardHipay-BankCardForm___de"
  }, {
    path: "/de/cart/components/payments/hipay/Bancontact/BanContactForm",
    component: _d4c74320,
    name: "cart-components-payments-hipay-Bancontact-BanContactForm___de"
  }, {
    path: "/de/cart/components/payments/hipay/Bancontact/BancontactHeader",
    component: _6e96f2d9,
    name: "cart-components-payments-hipay-Bancontact-BancontactHeader___de"
  }, {
    path: "/de/cart/components/payments/hipay/Giropay/GiropayForm",
    component: _76fe96f8,
    name: "cart-components-payments-hipay-Giropay-GiropayForm___de"
  }, {
    path: "/de/cart/components/payments/hipay/Giropay/GiropayHeader",
    component: _608253cd,
    name: "cart-components-payments-hipay-Giropay-GiropayHeader___de"
  }, {
    path: "/de/cart/components/payments/hipay/Ideal/IdealForm",
    component: _5cc6a1dc,
    name: "cart-components-payments-hipay-Ideal-IdealForm___de"
  }, {
    path: "/de/cart/components/payments/hipay/Ideal/IdealHeader",
    component: _01fb69b6,
    name: "cart-components-payments-hipay-Ideal-IdealHeader___de"
  }, {
    path: "/de/cart/components/payments/hipay/Sofort/SofortForm",
    component: _1f82ac60,
    name: "cart-components-payments-hipay-Sofort-SofortForm___de"
  }, {
    path: "/de/cart/components/payments/hipay/Sofort/SofortHeader",
    component: _d6cfb3ce,
    name: "cart-components-payments-hipay-Sofort-SofortHeader___de"
  }, {
    path: "/en/cart/components/payments/hipay/BancCardHipay/BancCardHipayHeader",
    component: _54778501,
    name: "cart-components-payments-hipay-BancCardHipay-BancCardHipayHeader___en"
  }, {
    path: "/en/cart/components/payments/hipay/BancCardHipay/BankCardForm",
    component: _df3537e2,
    name: "cart-components-payments-hipay-BancCardHipay-BankCardForm___en"
  }, {
    path: "/en/cart/components/payments/hipay/Bancontact/BanContactForm",
    component: _d4c74320,
    name: "cart-components-payments-hipay-Bancontact-BanContactForm___en"
  }, {
    path: "/en/cart/components/payments/hipay/Bancontact/BancontactHeader",
    component: _6e96f2d9,
    name: "cart-components-payments-hipay-Bancontact-BancontactHeader___en"
  }, {
    path: "/en/cart/components/payments/hipay/Giropay/GiropayForm",
    component: _76fe96f8,
    name: "cart-components-payments-hipay-Giropay-GiropayForm___en"
  }, {
    path: "/en/cart/components/payments/hipay/Giropay/GiropayHeader",
    component: _608253cd,
    name: "cart-components-payments-hipay-Giropay-GiropayHeader___en"
  }, {
    path: "/en/cart/components/payments/hipay/Ideal/IdealForm",
    component: _5cc6a1dc,
    name: "cart-components-payments-hipay-Ideal-IdealForm___en"
  }, {
    path: "/en/cart/components/payments/hipay/Ideal/IdealHeader",
    component: _01fb69b6,
    name: "cart-components-payments-hipay-Ideal-IdealHeader___en"
  }, {
    path: "/en/cart/components/payments/hipay/Sofort/SofortForm",
    component: _1f82ac60,
    name: "cart-components-payments-hipay-Sofort-SofortForm___en"
  }, {
    path: "/en/cart/components/payments/hipay/Sofort/SofortHeader",
    component: _d6cfb3ce,
    name: "cart-components-payments-hipay-Sofort-SofortHeader___en"
  }, {
    path: "/fr/cart/components/payments/hipay/BancCardHipay/BancCardHipayHeader",
    component: _54778501,
    name: "cart-components-payments-hipay-BancCardHipay-BancCardHipayHeader___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/BancCardHipay/BankCardForm",
    component: _df3537e2,
    name: "cart-components-payments-hipay-BancCardHipay-BankCardForm___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Bancontact/BanContactForm",
    component: _d4c74320,
    name: "cart-components-payments-hipay-Bancontact-BanContactForm___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Bancontact/BancontactHeader",
    component: _6e96f2d9,
    name: "cart-components-payments-hipay-Bancontact-BancontactHeader___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Giropay/GiropayForm",
    component: _76fe96f8,
    name: "cart-components-payments-hipay-Giropay-GiropayForm___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Giropay/GiropayHeader",
    component: _608253cd,
    name: "cart-components-payments-hipay-Giropay-GiropayHeader___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Ideal/IdealForm",
    component: _5cc6a1dc,
    name: "cart-components-payments-hipay-Ideal-IdealForm___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Ideal/IdealHeader",
    component: _01fb69b6,
    name: "cart-components-payments-hipay-Ideal-IdealHeader___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Sofort/SofortForm",
    component: _1f82ac60,
    name: "cart-components-payments-hipay-Sofort-SofortForm___fr"
  }, {
    path: "/fr/cart/components/payments/hipay/Sofort/SofortHeader",
    component: _d6cfb3ce,
    name: "cart-components-payments-hipay-Sofort-SofortHeader___fr"
  }, {
    path: "/de/account/service/request/:productAttribute?",
    component: _3468e383,
    name: "account-service-request-productAttribute___de"
  }, {
    path: "/de/account/service/tickets/:idTickets",
    component: _fdc21164,
    name: "account-service-tickets-idTickets___de"
  }, {
    path: "/en/account/service/request/:productAttribute?",
    component: _3468e383,
    name: "account-service-request-productAttribute___en"
  }, {
    path: "/en/account/service/tickets/:idTickets",
    component: _fdc21164,
    name: "account-service-tickets-idTickets___en"
  }, {
    path: "/fr/account/service/request/:productAttribute?",
    component: _3468e383,
    name: "account-service-request-productAttribute___fr"
  }, {
    path: "/fr/account/service/tickets/:idTickets",
    component: _fdc21164,
    name: "account-service-tickets-idTickets___fr"
  }, {
    path: "/de/account/address/:idAddress",
    component: _644f4394,
    name: "account-address-idAddress___de"
  }, {
    path: "/de/account/orders/:id",
    component: _63538acf,
    name: "account-orders-id___de"
  }, {
    path: "/de/account/return/:id",
    component: _26abf51d,
    name: "account-return-id___de"
  }, {
    path: "/de/account/returns/:id",
    component: _5ba9d202,
    name: "account-returns-id___de"
  }, {
    path: "/de/content/faq/:permalink",
    component: _155b3178,
    name: "content-faq-permalink___de"
  }, {
    path: "/en/account/address/:idAddress",
    component: _644f4394,
    name: "account-address-idAddress___en"
  }, {
    path: "/en/account/orders/:id",
    component: _63538acf,
    name: "account-orders-id___en"
  }, {
    path: "/en/account/return/:id",
    component: _26abf51d,
    name: "account-return-id___en"
  }, {
    path: "/en/account/returns/:id",
    component: _5ba9d202,
    name: "account-returns-id___en"
  }, {
    path: "/en/content/faq/:permalink",
    component: _155b3178,
    name: "content-faq-permalink___en"
  }, {
    path: "/fr/account/address/:idAddress",
    component: _644f4394,
    name: "account-address-idAddress___fr"
  }, {
    path: "/fr/account/orders/:id",
    component: _63538acf,
    name: "account-orders-id___fr"
  }, {
    path: "/fr/account/return/:id",
    component: _26abf51d,
    name: "account-return-id___fr"
  }, {
    path: "/fr/account/returns/:id",
    component: _5ba9d202,
    name: "account-returns-id___fr"
  }, {
    path: "/fr/content/faq/:permalink",
    component: _155b3178,
    name: "content-faq-permalink___fr"
  }, {
    path: "/de/account/return/:id?/confirmed",
    component: _7d8a6faa,
    name: "account-return-id-confirmed___de"
  }, {
    path: "/en/account/return/:id?/confirmed",
    component: _7d8a6faa,
    name: "account-return-id-confirmed___en"
  }, {
    path: "/fr/account/return/:id?/confirmed",
    component: _7d8a6faa,
    name: "account-return-id-confirmed___fr"
  }, {
    path: "/de/cart/thanks/:state?/:idorder?",
    component: _dd661226,
    name: "cart-thanks-state-idorder___de"
  }, {
    path: "/en/cart/thanks/:state?/:idorder?",
    component: _dd661226,
    name: "cart-thanks-state-idorder___en"
  }, {
    path: "/fr/cart/thanks/:state?/:idorder?",
    component: _dd661226,
    name: "cart-thanks-state-idorder___fr"
  }, {
    path: "/de/account/:tab",
    component: _2604ab18,
    name: "account-tab___de"
  }, {
    path: "/de/authenticate/:route?",
    component: _71931312,
    name: "authenticate-route___de"
  }, {
    path: "/de/blog/:uid?",
    component: _0b4e5351,
    name: "blog-uid___de"
  }, {
    path: "/de/blogs/:permalink?",
    component: _02a7f887,
    name: "blogs-permalink___de"
  }, {
    path: "/de/geschaft/:permalink",
    component: _01f35850,
    name: "shop-permalink___de"
  }, {
    path: "/de/lookbooks/:permalink",
    component: _5aa72726,
    name: "lookbooks-permalink___de"
  }, {
    path: "/de/product/:product?",
    component: _3bd97d2d,
    name: "product-product___de"
  }, {
    path: "/de/referral/:code?",
    component: _48d42e77,
    name: "referral-code___de"
  }, {
    path: "/de/shop-the-look/:product?",
    component: _2432a86c,
    name: "shop-the-look-product___de"
  }, {
    path: "/en/account/:tab",
    component: _2604ab18,
    name: "account-tab___en"
  }, {
    path: "/en/authenticate/:route?",
    component: _71931312,
    name: "authenticate-route___en"
  }, {
    path: "/en/blog/:uid?",
    component: _0b4e5351,
    name: "blog-uid___en"
  }, {
    path: "/en/blogs/:permalink?",
    component: _02a7f887,
    name: "blogs-permalink___en"
  }, {
    path: "/en/lookbooks/:permalink",
    component: _5aa72726,
    name: "lookbooks-permalink___en"
  }, {
    path: "/en/product/:product?",
    component: _3bd97d2d,
    name: "product-product___en"
  }, {
    path: "/en/referral/:code?",
    component: _48d42e77,
    name: "referral-code___en"
  }, {
    path: "/en/shop-the-look/:product?",
    component: _2432a86c,
    name: "shop-the-look-product___en"
  }, {
    path: "/en/shop/:permalink",
    component: _01f35850,
    name: "shop-permalink___en"
  }, {
    path: "/fr/account/:tab",
    component: _2604ab18,
    name: "account-tab___fr"
  }, {
    path: "/fr/authenticate/:route?",
    component: _71931312,
    name: "authenticate-route___fr"
  }, {
    path: "/fr/blog/:uid?",
    component: _0b4e5351,
    name: "blog-uid___fr"
  }, {
    path: "/fr/blogs/:permalink?",
    component: _02a7f887,
    name: "blogs-permalink___fr"
  }, {
    path: "/fr/boutique/:permalink",
    component: _01f35850,
    name: "shop-permalink___fr"
  }, {
    path: "/fr/lookbooks/:permalink",
    component: _5aa72726,
    name: "lookbooks-permalink___fr"
  }, {
    path: "/fr/product/:product?",
    component: _3bd97d2d,
    name: "product-product___fr"
  }, {
    path: "/fr/referral/:code?",
    component: _48d42e77,
    name: "referral-code___fr"
  }, {
    path: "/fr/shop-the-look/:product?",
    component: _2432a86c,
    name: "shop-the-look-product___fr"
  }, {
    path: "/de/account/:tab?/:tab2",
    component: _22f3b962,
    name: "account-tab-tab2___de"
  }, {
    path: "/en/account/:tab?/:tab2",
    component: _22f3b962,
    name: "account-tab-tab2___en"
  }, {
    path: "/fr/account/:tab?/:tab2",
    component: _22f3b962,
    name: "account-tab-tab2___fr"
  }, {
    path: "/de/:cat",
    component: _6ad96af7,
    name: "cat___de"
  }, {
    path: "/en/:cat",
    component: _6ad96af7,
    name: "cat___en"
  }, {
    path: "/fr/:cat",
    component: _6ad96af7,
    name: "cat___fr"
  }, {
    path: "/de/:cat/:product",
    component: _c73cc2ca,
    name: "cat-product___de"
  }, {
    path: "/en/:cat/:product",
    component: _c73cc2ca,
    name: "cat-product___en"
  }, {
    path: "/fr/:cat/:product",
    component: _c73cc2ca,
    name: "cat-product___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
